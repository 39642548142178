export const setUserData = (data) => {
  localStorage.setItem('userData', JSON.stringify(data))
}
export const setUserToken = (token) => {
  localStorage.setItem('token', `Bearer ${token}`)
}
export const setUserLoginData = (data) => {
  localStorage.setItem('userLoginData', JSON.stringify(data))
}

export const getUserToken = () => {
  return localStorage.getItem('token')
}
export const getUserData = () => {
  return JSON.parse(localStorage.getItem('userData'))
}
export const getUserLoginData = (data) => {
  return JSON.parse(localStorage.getItem('userLoginData'))
}

export const unsetUserData = (data) => {
  localStorage.removeItem('userData')
}
export const unsetUserToken = (token) => {
  localStorage.removeItem('token')
}
export const unsetUserLoginData = (data) => {
  localStorage.removeItem('userLoginData')
}
