import * as types from './types'

export const fetchProducts = ({ filters, resolve, reject }) => ({
  type: types.FETCH_PRODUCTS,
  filters,
  resolve,
  reject
})

export const setProducts = ({ data, stats }) => ({
  type: types.SET_PRODUCTS,
  data,
  stats
})
export const productActivation = (id) => ({
  type: types.PRODUCT_ACTIVATION,
  id
})
export const updateProductActivation = (id, is_active) => ({
  type: types.SET_PRODUCT_ACTIVATION,
  id,
  is_active
})

export const productTrending = (id) => ({
  type: types.PRODUCT_TRENDING,
  id
})
export const updateProductTrending = (id, is_trending) => ({
  type: types.SET_PRODUCT_TRENDING,
  id,
  is_trending
})
export const createProduct = ({ data, resolve, reject }) => ({
  type: types.CREATE_PRODUCT,
  data,
  resolve,
  reject
})
export const updateProduct = ({ id, data, resolve, reject }) => ({
  type: types.UPDATE_PRODUCT,
  id,
  data,
  resolve,
  reject
})
