import Api from '../../../api'
import routes from './index'

export function authLogin(data) {
  data.uuid = process.env.REACT_APP_ADMIN_UUID
  if (data.loginAs === 'administrator') {
    return Api(routes.ADMIN_LOGIN, data, 'post', '')
  } else {
    data.user_type = data.loginAs
    delete data.loginAs
    delete data.uuid
    return Api(routes.EMPLOYEE_LOGIN, data, 'post', '')
  }
}

export function authConfirm(token) {
  return Api(routes.AUTH_GET, null, 'get', token)
}

export function registerUser(data) {
  return Api(routes.AUTH_REGISTER, data, 'post', '')
}

export function resetPassword(data) {
  return Api(routes.RESET_PASSWORD, data, 'post', '')
}

export function changePassword(data, token) {
  return Api(routes.CHANGE_PASSWORD, data, 'post', token)
}

export function logout(token) {
  return Api('logout', { login_type: 'web' }, 'post', token)
}
