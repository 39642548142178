import Api from '../../../api'
import * as storageUtils from '../../../utils/storageUtils'
import { convertObjectToQuerystring } from '../../../utils/helper'
var _ = require('lodash')

export const fetchOrders = (filters) => {
  let token = storageUtils.getUserToken()
  let filter = ''
  if (!_.isEmpty(filters)) {
    filter = convertObjectToQuerystring(filters)
  }
  return Api(`order?${filter}`, null, 'get', token)
}

export const changeOrderStatus = (id, data) => {
  let token = storageUtils.getUserToken()
  data._method = 'patch'
  return Api(`order/${id}`, data, 'post', token)
}

export const deleteOrder = (id) => {
  let token = storageUtils.getUserToken()
  return Api(`order/${id}`, null, 'delete', token)
}

export const fetchOrderDetail = (id) => {
  return Api(`order/${id}`, null, 'get', storageUtils.getUserToken())
}
export const partialDelivery = (data) => {
  let token = storageUtils.getUserToken()
  return Api(`delivery`, data, 'post', token)
}

export const ordersByProduct = (pr_code, filters) => {
  let token = storageUtils.getUserToken()
  let filter = ''
  if (!_.isEmpty(filters)) {
    filter = convertObjectToQuerystring(filters)
  }
  return Api(`order/listByProdcut/${pr_code}?${filter}`, null, 'get', token)
}

export const manageCart = (data) => {
  let token = storageUtils.getUserToken()
  return Api(`cart`, data, 'post', token)
}
