import * as types from '../actions/types'

const initialState = {
  categories: [],
  stats: {}
}

export default (state = initialState, action) => {
  let { categories } = state
  switch (action.type) {
    case types.SET_CATEGORIES:
      return {
        ...state,
        categories: action.data,
        stats: action.stats
      }
    case types.SET_CATEGORY_ACTIVATION:
      categories = categories.map((d) => {
        if (d.id === action.id) {
          d.is_active = action.is_active
        }
        return d
      })
      return {
        ...state,
        categories: categories
      }
    default:
      return state
  }
}
