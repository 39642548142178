import * as types from './types'

export const fetchOrders = (filters, resolve, reject) => ({
  type: types.FETCH_ORDERS,
  filters,
  resolve,
  reject
})

export const setOrders = ({ data, stats }) => ({
  type: types.SET_ORDERS,
  data,
  stats
})
