import { all, call, fork, put, takeLeading } from 'redux-saga/effects'
import * as types from '../actions/types'
import * as cmsActions from '../actions'
import * as cmsAPI from '../api/methods'
import toast from '../../../utils/toast'

export const getCurrentRoute = (state) => state.router.location.pathname

function* fetchCMS({ resolve, reject }) {
  try {
    const response = yield call(cmsAPI.fetchCMS)
    yield put(cmsActions.setCMS({ data: response }))
    resolve()
  } catch (error) {
    reject()
    console.log(error)
  }
}

function* updateCMS({ id, data, resolve, reject }) {
  try {
    yield call(cmsAPI.updateCMS, { data, id })
    toast.success(`Page updated.`)
    resolve()
  } catch (err) {
    reject()
    err.then((response) => {
      response._errors.forEach((message) => {
        toast.error({ message })
      })
    })
  }
}
export function* watchLoginUser() {
  yield takeLeading(types.FETCH_CMS, fetchCMS)
  yield takeLeading(types.UPDATE_CMS, updateCMS)
}

export default function* userSaga() {
  yield all([fork(watchLoginUser)])
}
