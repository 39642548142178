import Api from '../../../api'
import * as storageUtils from '../../../utils/storageUtils'

export function fetchCMS() {
  let token = storageUtils.getUserToken()
  return Api(`cms`, null, 'get', token)
}
export function fetchSingleCMS(id) {
  let token = storageUtils.getUserToken()
  return Api(`cms/${id}`, null, 'get', token)
}
export function updateCMS(id, data) {
  data._method = 'patch'
  return Api(`cms/${id}`, data, 'post', storageUtils.getUserToken())
}
