import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'

import sagas from './sagas'
import rootReducer from './reducers'

export const history = createBrowserHistory()

// create the saga middleware
const sagaMiddleware = createSagaMiddleware()

let middlewares = [sagaMiddleware, routerMiddleware(history)]
export function configureStore(initialState) {
  const store = createStore(rootReducer(history), initialState, process.env.NODE_ENV === 'development' ? composeWithDevTools(applyMiddleware(...middlewares)) : applyMiddleware(...middlewares))

  sagaMiddleware.run(sagas)

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const nextRootReducer = require('./reducers')
      store.replaceReducer(nextRootReducer)
    })
  }

  return store
}
