import Api from '../../../api'
import * as storageUtils from '../../../utils/storageUtils'
import { convertObjectToQuerystring } from '../../../utils/helper'
var _ = require('lodash')

export function fetchCategories(filters) {
  let token = storageUtils.getUserToken()
  let filter = ''
  if (!_.isEmpty(filters)) {
    filter = `&${convertObjectToQuerystring(filters)}`
  }
  return Api(`categories?${filter}`, null, 'get', token)
}

export function categoryActivation(id) {
  let token = storageUtils.getUserToken()
  return Api(`categories/${id}/activation`, { _method: 'patch' }, 'post', token)
}

export function createCategory(data) {
  let token = storageUtils.getUserToken()
  return Api(`categories`, data, 'post', token)
}
export function fetchCategoryDetail(id) {
  let token = storageUtils.getUserToken()
  return Api(`categories/${id}`, null, 'get', token)
}
export function updateCategory({ data, id }) {
  let token = storageUtils.getUserToken()
  data._method = 'patch'
  return Api(`categories/${id}`, data, 'post', token)
}
export function deleteCategory(id) {
  let token = storageUtils.getUserToken()
  return Api(`categories/${id}`, null, 'delete', token)
}

// UNITS
export function fetchUnits() {
  let token = storageUtils.getUserToken()
  return Api(`units`, null, 'get', token)
}

// PACKAGE TYPES
export function fetchPackageTypes() {
  let token = storageUtils.getUserToken()
  return Api(`packagingTypes`, null, 'get', token)
}

export function fetchLastOrderIndex() {
  let token = storageUtils.getUserToken()
  return Api(`categories/lastOrderIndex`, null, 'get', token)
}
