import { all, call, fork, put, takeLeading } from 'redux-saga/effects'
import * as types from '../actions/types'
import * as actions from '../actions'
import * as apis from '../api/methods'

export const getCurrentRoute = (state) => state.router.location.pathname

function* fetchNotificationSaga({ filters }) {
  try {
    const response = yield call(apis.fetchAdminNotifications, filters)
    yield put(actions.setAdminNotifications({ data: response.data, stats: response.stats }))
  } catch (error) {
    console.log(error)
  }
}
export function* watchCategorySagas() {
  yield takeLeading(types.GET_ADMIN_NOTIFICATIONS, fetchNotificationSaga)
}

export default function* categorySaga() {
  yield all([fork(watchCategorySagas)])
}
