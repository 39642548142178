import * as types from '../actions/types'

const initialState = {
  distributor: {
    list: [],
    stats: {}
  },
  retailer: {
    list: [],
    stats: {}
  },
  company_employee: {
    list: [],
    stats: {}
  }
}

export default (state = initialState, action) => {
  let {
    distributor: { list }
  } = state
  switch (action.type) {
    case types.SET_USERS:
      return {
        ...state,
        [action.user_type]: {
          list: action.data,
          stats: action.stats
        }
      }
    case types.SET_CONFIRM_USER:
      list = state[action.user_type].list.map((d) => {
        if (d.id === action.id) {
          d.confirmed = true
        }
        return d
      })
      return {
        ...state,
        [action.user_type]: {
          ...state[action.user_type],
          list
        }
      }
    case types.SET_USER_ACTIVATION:
      list = state[action.user_type].list.map((d) => {
        if (d.id === action.id) {
          d.active = action.active
        }
        return d
      })
      return {
        ...state,
        [action.user_type]: {
          ...state[action.user_type],
          list
        }
      }
    default:
      return state
  }
}
