import { all, call, fork, put, takeLeading } from 'redux-saga/effects'
import * as types from '../actions/types'
import * as productsActions from '../actions'
import * as productsApi from '../api/methods'
import toast from '../../../utils/toast'

export const getCurrentRoute = (state) => state.router.location.pathname

function* fetchProducts({ filters, resolve, reject }) {
  try {
    const response = yield call(productsApi.fetchProducts, filters)
    yield put(productsActions.setProducts({ data: response.data, stats: response.stats }))
    resolve()
  } catch (error) {
    reject()
    console.log(error)
  }
}
function* productActivation({ id }) {
  try {
    const response = yield call(productsApi.productActivation, id)
    yield put(productsActions.updateProductActivation(id, response.is_active))
  } catch (err) {
    err.then((response) => {
      response._errors.forEach((message) => {
        toast.error({ message })
      })
    })
  }
}
function* productTrending({ id }) {
  try {
    const response = yield call(productsApi.productTrending, id)
    yield put(productsActions.updateProductTrending(id, response.is_trending))
  } catch (err) {
    err.then((response) => {
      response._errors.forEach((message) => {
        toast.error({ message })
      })
    })
  }
}

function* createProduct({ data, resolve, reject }) {
  try {
    yield call(productsApi.createProduct, data)
    toast.success('Product created')
    resolve()
  } catch (err) {
    reject()
    err.then((response) => {
      response._errors.forEach((message) => {
        toast.error({ message })
      })
    })
  }
}
function* updateProduct({ id, data, resolve, reject }) {
  try {
    yield call(productsApi.updateProduct, { data, id })
    toast.success('Product updated')
    resolve()
  } catch (err) {
    reject()
    err.then((response) => {
      response._errors.forEach((message) => {
        toast.error({ message })
      })
    })
  }
}
export function* watchProductSagas() {
  yield takeLeading(types.FETCH_PRODUCTS, fetchProducts)
  yield takeLeading(types.PRODUCT_ACTIVATION, productActivation)
  yield takeLeading(types.PRODUCT_TRENDING, productTrending)
  yield takeLeading(types.CREATE_PRODUCT, createProduct)
  yield takeLeading(types.UPDATE_PRODUCT, updateProduct)
}

export default function* productSaga() {
  yield all([fork(watchProductSagas)])
}
