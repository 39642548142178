import * as types from '../actions/types'

export const fetchCategories = ({ filters, resolve, reject }) => ({
  type: types.FETCH_CATEGORIES,
  filters,
  resolve,
  reject
})

export const setCategories = ({ data, stats }) => ({
  type: types.SET_CATEGORIES,
  data,
  stats
})
export const categoryActivation = (id) => ({
  type: types.CATEGORY_ACTIVATION,
  id
})
export const updateCategoryActivation = (id, is_active) => ({
  type: types.SET_CATEGORY_ACTIVATION,
  id,
  is_active
})

export const createCategory = ({ data, resolve, reject }) => ({
  type: types.CREATE_CATEGORY,
  data,
  resolve,
  reject
})
export const updateCategory = ({ id, data, resolve, reject }) => ({
  type: types.UPDATE_CATEGORY,
  id,
  data,
  resolve,
  reject
})
