import { all, call, fork, put, takeLeading } from 'redux-saga/effects'
import * as types from '../actions/types'
import * as userActions from '../actions'
import * as userApi from '../api/methods'
import toast from '../../../utils/toast'

export const getCurrentRoute = (state) => state.router.location.pathname

function* fetchUsers({ filters, resolve, reject }) {
  try {
    const response = yield call(userApi.fetchUsers, filters)
    yield put(userActions.setUsers({ data: response.data, stats: response.stats, user_type: filters.user_type }))
    resolve()
  } catch (error) {
    reject()
    console.log(error)
  }
}

function* confirmUser({ id, user_type }) {
  try {
    yield call(userApi.confirmUser, id)
    yield put(userActions.updateUserConfirmation({ id, user_type }))
  } catch (err) {
    err.then((response) => {
      response._errors.forEach((message) => {
        toast.error({ message })
      })
    })
  }
}

function* userActivation({ id, user_type }) {
  try {
    const response = yield call(userApi.userActivation, id)
    yield put(userActions.updateUserActivation(id, response.active, user_type))
  } catch (err) {
    err.then((response) => {
      response._errors.forEach((message) => {
        toast.error({ message })
      })
    })
  }
}

function* createUser({ data, resolve, reject }) {
  try {
    yield call(userApi.createUser, data)
    toast.success(`${data.user_type} created`)
    resolve()
    // history.push(`/${data.user_type}s`);
  } catch (err) {
    reject()
    err.then((response) => {
      response._errors.forEach((message) => {
        toast.error({ message })
      })
    })
  }
}

function* updateUser({ id, data, resolve, reject }) {
  try {
    yield call(userApi.updateUser, { data, id })
    toast.success(`${data.user_type} updated`)
    resolve()
    // history.push(`/${data.user_type}s`);
  } catch (err) {
    reject()
    err.then((response) => {
      response._errors.forEach((message) => {
        toast.error({ message })
      })
    })
  }
}
export function* watchLoginUser() {
  yield takeLeading(types.FETCH_USERS, fetchUsers)
  yield takeLeading(types.CONFIRM_USER, confirmUser)
  yield takeLeading(types.USER_ACTIVATION, userActivation)
  yield takeLeading(types.CREATE_USER, createUser)
  yield takeLeading(types.UPDATE_USER, updateUser)
}

export default function* userSaga() {
  yield all([fork(watchLoginUser)])
}
