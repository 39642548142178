import { all, call, fork, put, takeLeading } from 'redux-saga/effects'
import * as types from '../actions/types'
import * as orderActions from '../actions'
import * as orderApi from '../api/methods'

function* fetchOrders({ filters, resolve, reject }) {
  try {
    const response = yield call(orderApi.fetchOrders, filters)
    yield put(orderActions.setOrders({ data: response.data, stats: response.stats }))
    resolve()
  } catch (error) {
    reject()
    console.log(error)
  }
}
export function* watchOrderSagas() {
  yield takeLeading(types.FETCH_ORDERS, fetchOrders)
}

export default function* productSaga() {
  yield all([fork(watchOrderSagas)])
}
