import styled from 'styled-components'

export const Container = styled.div`
  z-index: 10001;
  position: fixed;
  right: 30px;
  top: 50px;
`

export const StyledToast = styled.div`
  position: relative;
  margin-bottom: 5px;
  width: 300px;
  padding: 15px 20px;
  border-radius: 3px;
  color: #fff;
  background: ${(props) => (props.type === 'success' ? '#0B875B' : '#E13C3C')};
  cursor: pointer;
  transition: all 0.15s;
  transform: translateZ(0);

  &.jira-toast-enter,
  &.jira-toast-exit.jira-toast-exit-active {
    opacity: 0;
    right: -10px;
  }

  &.jira-toast-exit,
  &.jira-toast-enter.jira-toast-enter-active {
    opacity: 1;
    right: 0;
  }
`

export const Title = styled.div`
  padding-right: 22px;
  font-size: 15px;
  font-weight: 500;
`

export const Message = styled.div`
  padding: 8px 10px 0 0;
  white-space: pre-wrap;
  font-size: 14px;
  font-weight: 500;
`
