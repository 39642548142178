import Api from '../../../api'
import routes from './index'
import * as storageUtils from '../../../utils/storageUtils'
import { convertObjectToQuerystring } from '../../../utils/helper'
var _ = require('lodash')

export function fetchUsers(filters) {
  let token = storageUtils.getUserToken()
  let filter = ''
  if (!_.isEmpty(filters)) {
    filter = `&${convertObjectToQuerystring(filters)}`
  }
  return Api(`${routes.FETCH_USERS}?${filter}`, null, 'get', token)
}
export function confirmUser(id) {
  let token = storageUtils.getUserToken()
  return Api(`user/${id}/confirm`, { _method: 'patch' }, 'post', token)
}
export function userActivation(id) {
  let token = storageUtils.getUserToken()
  return Api(`user/${id}/active`, { _method: 'patch' }, 'post', token)
}

export function createUser(data) {
  return Api('auth/register', data, 'post')
}

export function fetchSingleUser(id) {
  return Api(`user/${id}`, null, 'get', storageUtils.getUserToken())
}

export function updateUser({ id, data }) {
  data._method = 'patch'
  return Api(`user/${id}`, data, 'post', storageUtils.getUserToken())
}
export function deleteUser(id) {
  let token = storageUtils.getUserToken()
  return Api(`user/${id}`, null, 'delete', token)
}
