import * as types from '../actions/types'

const initialState = {
  isActive: false,
  list: [],
  stats: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_NOTIFCATION_ASIDE:
      return {
        ...state,
        isActive: !state.isActive
      }
    case types.SET_ADMIN_NOTIFICATIONS:
      return {
        ...state,
        list: action.data.data,
        stats: action.data.stats
      }
    default:
      return state
  }
}
