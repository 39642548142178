const routes = {
  PROFILE: 'user/info',
  ADMIN_LOGIN: 'auth/adminLogin',
  EMPLOYEE_LOGIN: 'auth/login',
  AUTH_REGISTER: 'auth/register',
  RESET_PASSWORD: 'auth/resetPassword',
  CHANGE_PASSWORD: 'auth/changePassword'
}

export default routes
