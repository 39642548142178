import * as types from './../actions/types'

const initialState = {
  list: [],
  stats: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_ORDERS:
      return {
        ...state,
        list: action.data,
        stats: action.stats
      }
    default:
      return state
  }
}
