import { all, call, fork, put, takeEvery, takeLeading, select } from 'redux-saga/effects'
import * as types from '../actions/types'
import * as userActions from '../actions'
import * as authApi from '../api/methods'
import * as storageUtils from '../../../utils/storageUtils'
import { replace, push } from 'connected-react-router'
import toast from '../../../utils/toast'
var _ = require('lodash')

export const getCurrentRoute = (state) => state.router.location.pathname

function* setAuthentication(data) {
  const user = _.cloneDeep(data)
  delete user.token
  const userData = {
    token: data.token,
    user
  }
  yield put(userActions.setLoggedInUser(userData))
  yield call(storageUtils.setUserToken, data.token)
  yield call(storageUtils.setUserData, user)
  toast.success('Login succeed.')
  setTimeout(() => {
    window.location.href = '/'
  }, 1000)
}

function* loginUser({ data, resolve, reject }) {
  let response
  try {
    const { email, password, loginAs, login_type } = data
    response = yield call(authApi.authLogin, { email, password, loginAs, login_type })
    yield setAuthentication(response)
    resolve()
  } catch (err) {
    err.then((response) => {
      response._errors.forEach((message) => {
        toast.error({ message })
      })
    })
    reject()
  }
}

function* logoutUser() {
  try {
    let token = yield call(storageUtils.getUserToken)
    yield call(authApi.logout, token)
    yield call(storageUtils.unsetUserData)
    yield call(storageUtils.unsetUserToken)
    yield put(userActions.setLoggedOutUser())
    yield put(replace('/login'))
  } catch (err) {}
}

function* restoreSession() {
  let token = yield call(storageUtils.getUserToken)
  let user = yield call(storageUtils.getUserData)
  if (token && token !== '') {
    try {
      const userData = {
        token: token,
        user: user
      }
      yield put(userActions.setLoggedInUser(userData))
    } catch (error) {
      yield put(push('/login'))
    }
  } else {
    const pathname = yield select(getCurrentRoute)
    if (!pathname.includes('login') && !pathname.includes('signup') && !pathname.includes('forgotpassword')) {
      yield put(push('/login'))
    }
  }
}

function* resetPassword(action) {
  let data
  try {
    data = yield call(authApi.resetPassword, action.data)
    toast.success(data.message)
    // console.log('resetPassword', data);
    yield put(push('/login'))
  } catch (err) {
    err.then((response) => {
      response._errors.forEach((message) => {
        toast.error({ message })
      })
    })
  }
}

export function* watchLoginUser() {
  yield takeEvery(types.LOGIN_USER, loginUser)
  yield takeEvery(types.LOGOUT_USER, logoutUser)
  yield takeEvery(types.RESTORE_SESSION, restoreSession)
  yield takeLeading(types.RESET_PASSWORD, resetPassword)
}

export default function* authSaga() {
  yield all([fork(watchLoginUser)])
}
