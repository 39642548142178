import * as types from './types'

export const toggleNotificationAside = () => ({
  type: types.TOGGLE_NOTIFCATION_ASIDE
})
export const fetchAdminNotifications = (filters) => ({
  type: types.GET_ADMIN_NOTIFICATIONS,
  filters
})
export const setAdminNotifications = (data) => ({
  type: types.SET_ADMIN_NOTIFICATIONS,
  data
})
