import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
// import { renderRoutes } from 'react-router-config';
import { connect } from 'react-redux'
import './App.scss'
import * as userActions from './modules/auth/actions'
import Toast from './shared/components/Toast'
const loading = () => <div className='animated fadeIn pt-3 text-center'>Loading...</div>

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'))

// Pages
const LoginContainer = React.lazy(() => import('./modules/auth/containers/LoginContainer.js'))
// const Register = React.lazy(() => import("./views/Pages/Register"));
// const Page404 = React.lazy(() => import("./views/Pages/Page404"));
// const Page500 = React.lazy(() => import("./views/Pages/Page500"));

class App extends Component {
  componentDidMount() {
    this.props.restoreSession()
  }
  render() {
    return (
      <React.Fragment>
        <Toast />
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route exact path='/login' name='Login Page' render={(props) => <LoginContainer {...props} />} />
            <Route path='/' name='Home' render={(props) => <DefaultLayout {...props} />} />
          </Switch>
        </React.Suspense>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ auth }) => ({
  auth: auth
})

const mapActionsToProps = (dispatch) => ({
  restoreSession: () => dispatch(userActions.restoreSession())
})

export default connect(mapStateToProps, mapActionsToProps)(App)
