import * as types from '../actions/types'

export const fetchCMS = ({ resolve, reject }) => ({
  type: types.FETCH_CMS,
  resolve,
  reject
})

export const setCMS = ({ data }) => ({
  type: types.SET_CMS,
  data
})

export const updateCMS = (id, data, resolve, reject) => ({
  type: types.UPDATE_CMS,
  id,
  data,
  resolve,
  reject
})
