import Api from '../../../api'
import * as storageUtils from '../../../utils/storageUtils'
import { convertObjectToQuerystring } from '../../../utils/helper'
var _ = require('lodash')

export function fetchMessages(filters) {
  let token = storageUtils.getUserToken()
  let filter = ''
  if (!_.isEmpty(filters)) {
    filter = `&${convertObjectToQuerystring(filters)}`
  }
  return Api(`notification/messsages?${filter}`, null, 'get', token)
}
export function sendSingleMessage(data) {
  let token = storageUtils.getUserToken()
  return Api(`notification`, data, 'post', token)
}
export function sendMultipleMessage(data) {
  let token = storageUtils.getUserToken()
  return Api(`notification/multiple`, data, 'post', token)
}
export function sendEveryoneMessage(data) {
  let token = storageUtils.getUserToken()
  return Api(`notification/everyone`, data, 'post', token)
}
export function fetchAdminNotifications(filters) {
  let token = storageUtils.getUserToken()
  let filter = ''
  if (!_.isEmpty(filters)) {
    filter = `&${convertObjectToQuerystring(filters)}`
  }
  return Api(`notification?${filter}`, null, 'get', token)
}
export function markAsRead() {
  let token = storageUtils.getUserToken()
  return Api(`notification/markAsRead`, { _method: 'patch' }, 'post', token)
}
