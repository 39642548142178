import Api from '../../../api'
import * as storageUtils from '../../../utils/storageUtils'
import { convertObjectToQuerystring } from '../../../utils/helper'
var _ = require('lodash')

export function fetchProducts(filters) {
  let token = storageUtils.getUserToken()
  let filter = ''
  if (!_.isEmpty(filters)) {
    filter = `&${convertObjectToQuerystring(filters)}`
  }
  return Api(`products?${filter}`, null, 'get', token)
}

export function productActivation(id) {
  let token = storageUtils.getUserToken()
  return Api(`products/${id}/activation`, { _method: 'patch' }, 'post', token)
}

export function productTrending(id) {
  let token = storageUtils.getUserToken()
  return Api(`products/${id}/trending`, { _method: 'patch' }, 'post', token)
}

export function createProduct(data) {
  let token = storageUtils.getUserToken()
  return Api(`products`, data, 'post', token)
}
export function fetchProductDetail(id) {
  let token = storageUtils.getUserToken()
  return Api(`products/${id}`, null, 'get', token)
}

export function updateProduct({ data, id }) {
  let token = storageUtils.getUserToken()
  data._method = 'patch'
  return Api(`products/${id}`, data, 'post', token)
}
export function deleteProduct(id) {
  let token = storageUtils.getUserToken()
  return Api(`products/${id}`, null, 'delete', token)
}

// COLOR
export function fetchColorVariants(category_id) {
  let token = storageUtils.getUserToken()
  return Api(`colors/${category_id}`, null, 'get', token)
}
export function addColorVariant(data) {
  let token = storageUtils.getUserToken()
  return Api(`colors`, data, 'post', token)
}

export function deleteColorVariant(id) {
  let token = storageUtils.getUserToken()
  return Api(`colors/${id}`, null, 'delete', token)
}

// Packing type master
export function addPackagingType(data) {
  let token = storageUtils.getUserToken()
  return Api(`packagingTypes`, data, 'post', token)
}
export function addUnit(data) {
  let token = storageUtils.getUserToken()
  return Api(`units`, data, 'post', token)
}

// PACKING OPTION
export function addPackingOption(data) {
  let token = storageUtils.getUserToken()
  return Api(`packingOptions`, data, 'post', token)
}
export function deletePackingOption(id) {
  let token = storageUtils.getUserToken()
  return Api(`packingOptions/${id}`, null, 'delete', token)
}

export function fetchLastOrderIndex() {
  let token = storageUtils.getUserToken()
  return Api(`products/lastOrderIndex`, null, 'get', token)
}
