import { all, call, fork, put, takeLeading } from 'redux-saga/effects'
import * as types from '../actions/types'
import * as categoriesActions from '../actions'
import * as categoriesApi from '../api/methods'
import toast from '../../../utils/toast'

export const getCurrentRoute = (state) => state.router.location.pathname

function* fetchCategories({ filters, resolve, reject }) {
  try {
    const response = yield call(categoriesApi.fetchCategories, filters)
    yield put(categoriesActions.setCategories({ data: response.data, stats: response.stats }))
    resolve()
  } catch (error) {
    reject()
    console.log(error)
  }
}
function* categoryActivation({ id }) {
  try {
    const response = yield call(categoriesApi.categoryActivation, id)
    yield put(categoriesActions.updateCategoryActivation(id, response.is_active))
  } catch (err) {
    err.then((response) => {
      response._errors.forEach((message) => {
        toast.error({ message })
      })
    })
  }
}

function* createCategory({ data, resolve, reject }) {
  try {
    yield call(categoriesApi.createCategory, data)
    toast.success('Category created')
    resolve()
  } catch (err) {
    reject()
    err.then((response) => {
      response._errors.forEach((message) => {
        toast.error({ message })
      })
    })
  }
}
function* updateCategory({ id, data, resolve, reject }) {
  try {
    yield call(categoriesApi.updateCategory, { data, id })
    toast.success('Category updated')
    resolve()
  } catch (err) {
    reject()
    err.then((response) => {
      response._errors.forEach((message) => {
        toast.error({ message })
      })
    })
  }
}
export function* watchCategorySagas() {
  yield takeLeading(types.FETCH_CATEGORIES, fetchCategories)
  yield takeLeading(types.CATEGORY_ACTIVATION, categoryActivation)
  yield takeLeading(types.CREATE_CATEGORY, createCategory)
  yield takeLeading(types.UPDATE_CATEGORY, updateCategory)
}

export default function* categorySaga() {
  yield all([fork(watchCategorySagas)])
}
