export const convertObjectToQuerystring = (obj) => {
  return Object.keys(obj)
    .map((key) => key + '=' + obj[key])
    .join('&')
}

export const isSpecialKeyCodes = [
  8, // Backspace
  9, // Tab
  13, // Enter
  37, // Left
  38, // Up
  39, // Right
  40 // Down
]

export const OrderStatuses = {
  '--': '-- Select Status --',
  1: 'Pending',
  bs_1: 'dark',
  icon_1: 'fa fa-circle-thin',

  2: 'Reject',
  bs_2: 'danger',
  icon_2: 'icons cui-trash',

  3: 'In Process',
  bs_3: 'warning',
  icon_3: 'icon-social-dropbox icons',

  5: 'Partial Delivered',
  bs_5: 'info',
  icon_5: 'fa fa-check',

  6: 'Fully Delivered',
  bs_6: 'success',
  icon_6: 'fa fa-check-square',

  7: 'User Cancelled',
  bs_7: 'danger',
  icon_7: 'icons cui-trash',

  8: 'Pending + Partial',
  bs_8: 'dark',
  icon_8: 'fa fa-circle-thin'
}

export const sortOrders = {
  0: 'asc',
  1: 'desc'
}

// none of react-select's styles are passed to <Control />
export const selectCustomStyle = {
  control: (_, { selectProps: { width } }) => ({
    width: width,
    display: 'flex',
    border: '1px solid #cccccc',
    borderRadius: 4,
    height: 30
  })
}