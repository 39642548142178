import * as types from '../actions/types'

const initialState = {
  list: [],
  stats: {}
}

export default (state = initialState, action) => {
  let { list } = state
  switch (action.type) {
    case types.SET_PRODUCTS:
      return {
        ...state,
        list: action.data,
        stats: action.stats
      }
    case types.SET_PRODUCT_ACTIVATION:
      list = list.map((d) => {
        if (d.id === action.id) {
          d.is_active = action.is_active
        }
        return d
      })
      return {
        ...state,
        list: list
      }
    case types.SET_PRODUCT_TRENDING:
      list = list.map((d) => {
        if (d.id === action.id) {
          d.is_trending = action.is_trending
        }
        return d
      })
      return {
        ...state,
        list: list
      }
    default:
      return state
  }
}
