import React, { useState, useEffect } from 'react'
import pubsub from 'sweet-pubsub'
import { uniqueId } from 'lodash'
import { Container, StyledToast, Title, Message } from './styles'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

const Toast = () => {
  const [toasts, setToasts] = useState([])

  useEffect(() => {
    const addToast = ({ type = 'success', title, message, duration = 5 }) => {
      const id = uniqueId('toast-')

      setToasts((currentToasts) => [...currentToasts, { id, type, title, message }])

      if (duration) {
        setTimeout(() => removeToast(id), duration * 1000)
      }
    }

    pubsub.on('toast', addToast)

    return () => {
      pubsub.off('toast', addToast)
    }
  }, [])

  const removeToast = (id) => {
    setToasts((currentToasts) => currentToasts.filter((toast) => toast.id !== id))
  }

  return (
    <Container>
      <TransitionGroup>
        {toasts.map((toast) => (
          <CSSTransition key={toast.id} timeout={500} classNames='toast'>
            <div>
              <StyledToast type={toast.type} onClick={() => removeToast(toast.id)}>
                {toast.title && <Title>{toast.title}</Title>}
                {toast.message && <Message>{toast.message}</Message>}
              </StyledToast>
            </div>
          </CSSTransition>
        ))}
      </TransitionGroup>
    </Container>
  )
}

export default Toast
