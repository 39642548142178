import { all } from 'redux-saga/effects'
import authSaga from '../modules/auth/sagas'
import userSaga from '../modules/users/sagas'
import categorySagas from '../modules/categories/sagas'
import productSagas from '../modules/products/sagas'
import orderSagas from '../modules/orders/sagas'
import cmsSagas from '../modules/cms/sagas'
import messageSagas from '../modules/messages/sagas'

export default function* rootSaga() {
  yield all([authSaga(), userSaga(), categorySagas(), productSagas(), orderSagas(), cmsSagas(), messageSagas()])
}
