import { history } from "../store/store"

export default function api(path, params, method, token, isFormData, fcmToken = null) {
  let options
  options = {
    headers:
      isFormData === true
        ? {
            Accept: 'application/json',
            ...(token && { Authorization: token }),
            ...(fcmToken && { FcmToken: fcmToken })
          }
        : {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...(token && { Authorization: token }),
            ...(fcmToken && { FcmToken: fcmToken })
          },
    method: method,
    ...(params && { body: isFormData ? params : JSON.stringify(params) })
  }

  return fetch(process.env.REACT_APP_API_URL + path, options).then((response) => {
    return new Promise((resolve, reject) => {
      if (response.status === 401) {
        history.push('/login')
        reject(response.json())
      } else if (response.status === 200) {
        resolve(response.json())
      } else {
        reject(response.json())
      }
    })
  })
}
