import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import authReducer from '../modules/auth/reducer'
import userReducer from '../modules/users/reducer'
import categoryReducer from '../modules/categories/reducer'
import productReducer from '../modules/products/reducer'
import orderReducer from '../modules/orders/reducer'
import cmsReducer from '../modules/cms/reducer'
import notificationReducer from '../modules/messages/reducer'

const rootReducer = (history) =>
  combineReducers({
    auth: authReducer,
    users: userReducer,
    category: categoryReducer,
    product: productReducer,
    orders: orderReducer,
    cms: cmsReducer,
    notification: notificationReducer,
    router: connectRouter(history)
  })
export default rootReducer
