import * as types from '../actions/types'

export const fetchUsers = ({ filters, resolve, reject }) => ({
  type: types.FETCH_USERS,
  filters,
  resolve,
  reject
})

export const setUsers = ({ data, stats, user_type }) => ({
  type: types.SET_USERS,
  data,
  stats,
  user_type
})

export const confirmUser = ({ id, user_type }) => ({
  type: types.CONFIRM_USER,
  id,
  user_type
})

export const updateUserConfirmation = ({ id, user_type }) => ({
  type: types.SET_CONFIRM_USER,
  id,
  user_type
})

export const userActivation = ({ id, user_type }) => ({
  type: types.USER_ACTIVATION,
  id,
  user_type
})

export const updateUserActivation = (id, active, user_type) => ({
  type: types.SET_USER_ACTIVATION,
  id,
  active,
  user_type
})

export const createUser = ({ data, resolve, reject }) => ({
  type: types.CREATE_USER,
  data,
  resolve,
  reject
})

export const updateUser = ({ id, data, resolve, reject }) => ({
  type: types.UPDATE_USER,
  id,
  data,
  resolve,
  reject
})
